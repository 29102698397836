import _isUndefined from 'lodash/isUndefined'

import { type Emissions, type EmissionsGroup } from 'types/data'

const isGroupSelected = (
  selectedEmissions: Emissions[],
  group: EmissionsGroup
): boolean => {
  const { polygons } = group

  return !polygons
    .map(
      ({ id }) =>
        !_isUndefined(
          selectedEmissions.find(
            ({ id: selectedEmissionsID }) => id === selectedEmissionsID
          )
        )
    )
    .includes(false)
}

export default isGroupSelected
