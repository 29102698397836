/* eslint @typescript-eslint/indent: 0 */
/* eslint @typescript-eslint/no-unnecessary-type-assertion: 0 */

import {
  useCallback,
  useContext,
  Fragment,
  useState,
  useMemo,
  useRef,
  type ReactNode
} from 'react'
import cn from 'clsx'
import _map from 'lodash/map'
import _flatten from 'lodash/flatten'
import _isFinite from 'lodash/isFinite'
import _isUndefined from 'lodash/isUndefined'
import { useTranslation } from 'react-i18next'

import * as CSV from 'csv'
import * as U from 'utils'
import * as H from 'hooks'
import * as OU from './utils'
import { CHART_COLORS } from 'config'
import { getLegendDimensions } from '../ChartLegend/utils'
import useTransform from '../BarChart/hooks/useTransform'
import useTransformAxisBottom from '../BarChart/hooks/useTransformAxisBottom'
import FluidContentWrapperContext from '../FluidContentWrapper/context'
import useScaleX from './hooks/useScaleX'
import useScaleY from './hooks/useScaleY'
import {
  type Emissions,
  type ChartDataPoint,
  type Dimensions,
  type DataType
} from 'types'
import { type i18n } from 'i18next'

import { PATH_ELEMENT_CLASS_NAME, CLASS_NAME } from './const'

import ChartHeader from 'components/ChartHeader/ChartHeader'
import ChartLegend from 'components/ChartLegend/ChartLegend'
import ChartTooltip from 'components/ChartTooltip/ChartTooltip'
import ChartAxisLeft from 'components/ChartAxisLeft/ChartAxisLeft'
import ChartAxisBottom from 'components/ChartAxisBottom/ChartAxisBottom'
import ChartAxisGridLines from '../ChartAxisGridLines/ChartAxisGridLines'

import './style.scss'

const getDataPointsFromSvgPath = (pathString: string): ChartDataPoint[] => {
  const allPoints = pathString
    .replace(/[MLT]/g, '')
    .trim()
    .split(/\s+/)
    .map(
      (str: string): ChartDataPoint => ({
        x: Number(str.split(',')[0]),
        y: Number(str.split(',')[1])
      })
    )

  // the first and the last coordinates are duplicated, so we are removing them
  return allPoints.slice(1, allPoints.length - 1)
}

const tooltipFormatX = (i18n: i18n, date: string | number | Date): string => {
  const isoLocaleCode = i18n.languages[0]
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    timeZone: 'UTC'
  }

  return new Intl.DateTimeFormat(isoLocaleCode, options).format(date as Date)
}

type TooltipData = {
  emissions: Emissions | null
  x: string | Date | number
  y: number
  dx: string | Date | number
  dy: number
}

type Props = {
  className?: string
  id: string
  extraControls?: ReactNode
  extraHeaderControls?: ReactNode
  title: string
  temporal?: boolean
  split?: boolean
  data: ChartDataPoint[] | ChartDataPoint[][]
  height: number
  labelX?: string
  labelY?: string
  formatX?: any
  formatY?: (y: number) => string
  tooltipEmissions?: Emissions[]
  dataIsIntensity?: boolean

  // NOTE: This is so we can add a 'tCO2/ha' or other suffix in the tooltip,
  // but not in the exported data or elsewhere.
  tooltipFormatY?: (y: number, dataType: DataType) => string
}

export default function LineChart({
  height: aHeight,
  className: aClassName,
  extraHeaderControls,
  tooltipEmissions,
  dataIsIntensity,
  tooltipFormatY,
  extraControls,
  temporal,
  labelX,
  labelY,
  split,
  title,
  data
}: Props) {
  const { t, i18n } = useTranslation()
  const { width: contentWrapperWidth } = useContext(FluidContentWrapperContext)
  const svgRef = useRef<any>(null)
  const containerRef = useRef<any>(null)
  const flatData = useMemo((): ChartDataPoint[] => {
    const d: ChartDataPoint[] =
      split === true
        ? _flatten(data as ChartDataPoint[][])
        : [...(data as ChartDataPoint[])]

    return d
  }, [split, data])

  const min = H.useFunctionMemo<number | undefined>(
    U.getEmissionsDataMinY,
    data
  )
  const max =
    H.useFunctionMemo<number | undefined>(U.getEmissionsDataMaxY, data) ??
    Infinity

  const marginTop = 40
  const marginRight = 60
  const marginBottom = 40
  const marginLeft = 40

  const width = contentWrapperWidth - 64
  const height = aHeight + marginBottom

  const scaleX = useScaleX(flatData, width - marginLeft - marginRight, temporal)
  const scaleY = useScaleY(min ?? 0, max ?? 0, aHeight)

  const className = H.useClassName(CLASS_NAME, aClassName)
  const paths = useMemo((): string[] => {
    if (split === true) {
      return (data as ChartDataPoint[][]).map((d) =>
        OU.genLinePathString(d, scaleX, scaleY)
      )
    } else {
      return [OU.genLinePathString(data as ChartDataPoint[], scaleX, scaleY)]
    }
  }, [scaleX, scaleY, data, split])

  // NOTE: We keep one tooltip ref active, and set it on circle element hover
  const tooltipRef = useRef<any>()
  const [activeTooltipData, setActiveTooltipData] =
    useState<TooltipData | null>(null)

  // NOTE: We need the x coordinate from the path, and the y coord from the
  // underlying data point for the tooltip
  const onPathPointMouseOver = useCallback(
    (x: string | Date | number, y: number, i: number, j: number) => {
      const emissions = _isUndefined(tooltipEmissions)
        ? null
        : tooltipEmissions[i]

      const d =
        split === true
          ? (data[i] as ChartDataPoint[])
          : (data as ChartDataPoint[])

      const dx = d[j].x
      const dy = d[j].y
      setActiveTooltipData({ emissions, x, y, dx, dy })
    },
    [setActiveTooltipData, tooltipEmissions, paths]
  )

  const onPathPointMouseOut = useCallback((): void => {
    setActiveTooltipData(null)
  }, [setActiveTooltipData])

  const transform = useTransform(marginLeft, marginTop)
  const transformAxisBottom = useTransformAxisBottom(aHeight)
  const onExportCSV = useCallback((): void => {
    const csv = CSV.generateChartCSV({
      t,
      data,
      emissions: tooltipEmissions,
      chartTitle:
        dataIsIntensity === true
          ? t('export.title_intensity')
          : t('export.title_nee')
    })

    const csvFilename =
      split === true
        ? `${t('export.groups_file_name_prefix')} ${data.length} ${t(
            'export.groups_file_name_suffix'
          )}`
        : t('export.file_name')

    U.downloadCSV(csv, csvFilename)
  }, [data, dataIsIntensity, tooltipEmissions, t, split])

  const onExportPNG = useCallback(() => {
    const { current } = containerRef

    // eslint-disable-next-line github/no-then,@typescript-eslint/no-unsafe-argument
    U.exportElementToPNG(current, height + 100, 'result.png').catch(
      (err: Error) => {
        console.error(err)
      }
    )
  }, [height, containerRef.current])

  const onExportJPG = useCallback(() => {
    const { current } = containerRef

    // eslint-disable-next-line github/no-then,@typescript-eslint/no-unsafe-argument
    U.exportElementToJPG(current, height + 100, 'result.jpg').catch(
      (err: Error) => {
        console.error(err)
      }
    )
  }, [height, containerRef.current])

  const [pathsHoveredStatuses, setPathsHoveredStatuses] = useState<boolean[]>(
    []
  )

  const onEmissionsHovered = useCallback(
    (i: number, isHovered: boolean): void => {
      if (pathsHoveredStatuses[i] === isHovered) {
        return
      }

      const nextPathsHoveredStatuses = [...pathsHoveredStatuses]
      nextPathsHoveredStatuses[i] = isHovered
      setPathsHoveredStatuses(nextPathsHoveredStatuses)
    },
    [setPathsHoveredStatuses]
  )

  const chartLegendDimensions = useMemo((): Dimensions => {
    if (paths.length < 1) {
      return { width: 0, height: 0 }
    }

    return paths.length < 1
      ? { width: 0, height: 0 }
      : getLegendDimensions(width, _map(tooltipEmissions ?? [], 'feature'))
  }, [paths, width, tooltipEmissions])

  const { height: chartLegendHeight } = chartLegendDimensions

  return (
    <div
      ref={containerRef}
      className={cn(className, {
        hovering: pathsHoveredStatuses.includes(true)
      })}
      style={{
        marginBottom: `${chartLegendHeight}px`
      }}
    >
      <ChartHeader
        title={title}
        onExportCSV={onExportCSV}
        onExportPNG={onExportPNG}
        onExportJPG={onExportJPG}
        controls={extraHeaderControls}
      />
      <div className="cst-line-chart-wrapper">
        <svg width={width} height={height + 100} ref={tooltipRef}>
          <g transform={transform} ref={svgRef}>
            <ChartAxisLeft
              scale={scaleY}
              label={labelY}
              chartWidth={width}
              chartHeight={height}
            />
            <ChartAxisBottom
              scale={scaleX}
              label={labelX}
              chartWidth={width}
              chartHeight={height}
              transform={transformAxisBottom}
            />
            <ChartAxisGridLines scaleX={scaleX} scaleY={scaleY} />
            {paths.map((path: string, i: number) => {
              if (path.length === 0) {
                const dataPoint =
                  split === true
                    ? (data as ChartDataPoint[][])[i][0]
                    : (data as ChartDataPoint[])[0]

                const { x, y } = dataPoint ?? {}

                if (!_isFinite(+x) || !_isFinite(y)) return null

                const dx = scaleX(x) as number
                const dy = scaleY(y) as number

                return (
                  <circle
                    onMouseOut={onPathPointMouseOut}
                    onMouseOver={onPathPointMouseOver.bind(null, dx, dy, 0, 0)}
                    className={cn(PATH_ELEMENT_CLASS_NAME, {
                      hovered: pathsHoveredStatuses[i],
                      highlight: true
                    })}
                    fill={CHART_COLORS[i % CHART_COLORS.length]}
                    cx={dx}
                    cy={dy}
                    r={3}
                  />
                )
              }

              return (
                <Fragment key={`path-${i}`}>
                  <path
                    fill="none"
                    strokeWidth={1.5}
                    stroke={CHART_COLORS[i % CHART_COLORS.length]}
                    className={cn(PATH_ELEMENT_CLASS_NAME, {
                      hovered: pathsHoveredStatuses[i]
                    })}
                    key={`path-${i}`}
                    d={path}
                  />
                  {getDataPointsFromSvgPath(path).map(({ x, y }, j: number) => (
                    <circle
                      onMouseOut={onPathPointMouseOut}
                      onMouseOver={onPathPointMouseOver.bind(null, x, y, i, j)}
                      className={cn(PATH_ELEMENT_CLASS_NAME, {
                        hovered: pathsHoveredStatuses[i]
                      })}
                      fill={CHART_COLORS[i % CHART_COLORS.length]}
                      key={`circle-${j}`}
                      cx={x as number}
                      cy={y as number}
                      r={3}
                    />
                  ))}
                </Fragment>
              )
            })}
            {activeTooltipData !== null && (
              <ChartTooltip
                width={200}
                labelX={labelX}
                labelY={labelY}
                formatX={tooltipFormatX.bind(null, i18n)}
                formatY={tooltipFormatY}
                x={+activeTooltipData.x}
                y={+activeTooltipData.y}
                dx={activeTooltipData.dx}
                dy={activeTooltipData.dy}
                dataIsIntensity={dataIsIntensity}
                feature={activeTooltipData?.emissions?.feature}
                persistent
                showDate
              />
            )}
            {paths.length > 1 && (
              <ChartLegend
                x={0}
                y={height + 32}
                chartWidth={width}
                emissions={tooltipEmissions ?? []}
                setEmissionsHovered={onEmissionsHovered}
              />
            )}
          </g>
        </svg>
      </div>
      {!_isUndefined(extraControls) && extraControls}
    </div>
  )
}
