import cn from 'clsx'
import _uniqBy from 'lodash/uniqBy'
import _isUndefined from 'lodash/isUndefined'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useMemo } from 'react'
import { type ListChildComponentProps, FixedSizeList } from 'react-window'

import * as H from 'hooks'
import * as OU from './utils'
import GroupListItem from 'components/GroupListItem/GroupListItem'
import EmissionsListHeader from 'components/EmissionsListHeader/EmissionsFilterHeader'
import { type Emissions, type EmissionsGroup } from 'types/data'

import { CLASS_NAME } from './const'
import { type GroupListProps } from './types'

import './style.scss'

interface GroupSelectionIndexResults {
  key: string
  firstSelectedEmissionsIndex: number
}

const GroupList: React.FC<GroupListProps> = (props: GroupListProps) => {
  const {
    groups,
    subTitle,
    className,
    itemWidthPX,
    scrollWindowWidth,
    selectedEmissions,
    scrollWindowHeight,
    showSelectionIndex,
    setSelectedEmissions
  } = props

  const { t } = useTranslation()
  const emissions = useMemo(
    (): Emissions[] => groups.map(({ polygons }) => polygons).flat(),
    [groups]
  )

  const onToggleGroupSelection = useCallback(
    (group: EmissionsGroup) => {
      const { polygons } = group
      const isSelected = OU.isGroupSelected(selectedEmissions, group)

      // prettier-ignore
      const nextSelectedEmissions = isSelected
        ? selectedEmissions.filter(
          (emissions: Emissions) => (
            _isUndefined(polygons.find(({ id }) => id === emissions.id))
          )
        )
        : _uniqBy([...selectedEmissions, ...polygons], ({ id }) => id)

      setSelectedEmissions(nextSelectedEmissions)
    },
    [selectedEmissions, groups]
  )

  const groupSelectionIndexes = useMemo((): number[] => {
    const results = groups
      .filter((group: EmissionsGroup) =>
        OU.isGroupSelected(selectedEmissions, group)
      )
      .map(
        ({ key, polygons }): GroupSelectionIndexResults => ({
          key,
          firstSelectedEmissionsIndex: selectedEmissions.findIndex(
            ({ id }) =>
              !_isUndefined(polygons.find(({ id: itemId }) => itemId === id))
          )
        })
      )

    results.sort(
      (
        { firstSelectedEmissionsIndex: a },
        { firstSelectedEmissionsIndex: b }
      ) => a - b
    )

    return groups.map(({ key: groupKey }) =>
      results.findIndex(({ key }) => key === groupKey)
    )
  }, [groups, selectedEmissions])

  const {
    onSelectAll,
    onDeselectAll,
    isSelectAllDisabled,
    isDeselectAllDisabled
  } = H.useEmissionsSelect(emissions, selectedEmissions, setSelectedEmissions)

  const title = `${groups.length.toLocaleString()} ${t(
    'group_list.title_suffix'
  )}`

  const GroupListRow: React.FC<ListChildComponentProps> = (
    props: ListChildComponentProps
  ): JSX.Element => {
    const { index, style, data } = props
    const group: EmissionsGroup = data[index]
    const finalStyle = {
      ...style,
      top: +(style?.top ?? 0) + 16,
      left: +(style?.left ?? 0) + 32,
      width: Math.max(0, (scrollWindowWidth ?? 0) - 64)
    }

    return (
      <li style={finalStyle} onClick={onToggleGroupSelection.bind(null, group)}>
        <GroupListItem
          group={group}
          width={itemWidthPX}
          selectedEmissions={selectedEmissions}
          setSelectedEmissions={setSelectedEmissions}
          selectionIndex={groupSelectionIndexes[index]}
          selected={OU.isGroupSelected(selectedEmissions, group)}
          showSelectionIndex={
            showSelectionIndex === true && groupSelectionIndexes[index] >= 0
          }
        />
      </li>
    )
  }

  return (
    <div className={cn(CLASS_NAME, className)}>
      <EmissionsListHeader
        title={title}
        subTitle={subTitle}
        onSelectAll={onSelectAll}
        onDeselectAll={onDeselectAll}
        isSelectAllEnabled={!isSelectAllDisabled}
        isDeselectAllEnabled={!isDeselectAllDisabled}
      />

      <ul className={`${CLASS_NAME}-inner`}>
        <FixedSizeList
          itemSize={252}
          itemData={groups}
          itemCount={groups.length}
          width={scrollWindowWidth ?? 0}
          height={Math.max(0, (scrollWindowHeight ?? 0) - 32)}
        >
          {GroupListRow}
        </FixedSizeList>
      </ul>
    </div>
  )
}

export default GroupList
export { CLASS_NAME }
export type { GroupListProps as PROPS }
