import _get from 'lodash/get'
import _isFinite from 'lodash/isFinite'
import _isUndefined from 'lodash/isUndefined'

import { GROUP_KEY_NONE } from 'config'
import { CountryCode } from 'const/ui'
import {
  SortMode,
  GroupMode,
  SORT_MODE_PROPERTY_PATH,
  GROUP_MODE_PROPERTY_PATH
} from 'types/ui'

import updateGroupEmissionsValues from './update_group_emissions_values'

import { IGBPLandCoverClassNames } from 'types/common'
import { type EmissionsGroup, type Emissions } from 'types/data'

export default function getGroupedEmissions(
  emissions: Emissions[],
  groupMode: GroupMode = GroupMode.None,
  sortMode?: SortMode
): EmissionsGroup[] {
  const groups: EmissionsGroup[] = []

  const getPolygonsWithUniqueTag = (emissions: Emissions[]) => {
    const polygonsWithTags = emissions.filter(
      (polygon) => polygon.feature.properties?.tags?.length > 0
    )

    return polygonsWithTags.flatMap((polygon) =>
      polygon.feature.properties.tags.map((tag) => ({
        ...polygon,
        feature: {
          ...polygon.feature,
          properties: {
            ...polygon.feature.properties,
            tags: [tag]
          }
        }
      }))
    )
  }

  const emissionsToGroup =
    groupMode === GroupMode.Tags
      ? getPolygonsWithUniqueTag(emissions)
      : emissions

  // prettier-ignore
  for (const polygon of emissionsToGroup) {
    const propertyPath = GROUP_MODE_PROPERTY_PATH[
      groupMode as keyof typeof GROUP_MODE_PROPERTY_PATH
    ]

    const propertyValue = (_get(polygon, propertyPath) ?? '') as string
    let groupKey = GROUP_KEY_NONE

    // Replace raw values for LCC & Country with UI labels.
    switch (groupMode) {
      case GroupMode.LandCover:
        groupKey = IGBPLandCoverClassNames[
          propertyValue as keyof typeof IGBPLandCoverClassNames
        ]
        break

      case GroupMode.Country:
        groupKey = CountryCode[
          propertyValue as keyof typeof CountryCode
        ]
        break

      case GroupMode.Tags:
        groupKey = propertyValue[0]
        break

      case GroupMode.None:
      default:
        groupKey = propertyValue.length === 0
          ? GROUP_KEY_NONE
          : propertyValue
        break
    }

    const groupIndex = groups.findIndex(
      ({ key }: { key: string }) => key === groupKey
    )

    if (groupIndex < 0) {
      groups.push({
        key: groupKey,
        polygons: [polygon],
        total: 0,
        totalIntensity: 0,
        area: 0
      })
    } else {
      groups[groupIndex].polygons.push(polygon)
    }

    // Populates total, totalIntensity, and total area
    updateGroupEmissionsValues(groups[groupIndex < 0 ? groups.length - 1 : groupIndex])
  }

  if (!_isUndefined(sortMode) && sortMode !== SortMode.None) {
    groups.sort((a: any, b: any) => {
      const sortKey = SORT_MODE_PROPERTY_PATH[sortMode]
      const aValue = _get(a, sortKey)
      const bValue = _get(b, sortKey)

      return _isFinite(aValue) ? bValue - aValue : bValue.localeCompare(aValue)
    })
  }

  return groups
}
