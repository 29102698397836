/* eslint @typescript-eslint/indent: 0 */

import * as d3 from 'd3'
import _sum from 'lodash/sum'
import _min from 'lodash/min'
import _max from 'lodash/max'
import { useMemo } from 'react'

import { type ChartDataPoint } from '../../../types/ui'

export default function useScaleX(
  data: ChartDataPoint[],
  width: number,
  temporal?: boolean
): any {
  if (temporal !== true) {
    return d3
      .scaleBand()
      .domain(data.map(({ x }) => x as string))
      .range([0, width])
  }

  const dataPointWidth = useMemo(
    (): number =>
      data.length < 2
        ? 0
        : _sum(
            data.slice(1).map(({ x }, i: number): number => +x - +data[i].x)
          ) /
          (data.length - 1),
    [data]
  )

  const minDate = useMemo(
    (): Date => new Date(_min(data.map(({ x }) => +x)) as number),
    [data]
  )

  const maxDate = useMemo(
    (): Date => new Date(_max(data.map(({ x }) => +x)) as number),
    [data]
  )

  return useMemo(
    (): any =>
      d3
        .scaleUtc()
        .domain([
          new Date(+minDate - dataPointWidth * 0.5),
          new Date(+maxDate + dataPointWidth)
        ])
        .range([0, width]),
    [width, minDate, maxDate]
  )
}
