import { Auth } from 'aws-amplify'
import { useCallback } from 'react'
import _isString from 'lodash/isString'
import _isUndefined from 'lodash/isUndefined'

import { AuthStates } from '../const'

type Props = {
  email: string
  password: string
  newPassword: string
  resetCode: string
  authState: any
  cognitoUserState: any
  setFormError: (error: string) => void
  setLoading: (loading: boolean) => void
  setAuthState: (authState: any) => void
  setCognitoUserState: (cognitoUserState: any) => void
  setUser: (user: any) => void
}

export default function useHandleSubmit({
  email,
  password,
  newPassword,
  resetCode,
  authState,
  cognitoUserState,
  setFormError,
  setLoading,
  setAuthState,
  setCognitoUserState,
  setUser
}: Props): () => Promise<void> {
  return useCallback(async (): Promise<void> => {
    try {
      let user

      setLoading(true)

      if (authState === AuthStates.ResetPassword) {
        const res = await Auth.forgotPassword(email)

        if (!_isUndefined(res.CodeDeliveryDetails)) {
          setAuthState(AuthStates.ResetPasswordStep2)
        }

        setLoading(false)

        return
      } else if (authState === AuthStates.ResetPasswordStep2) {
        await Auth.forgotPasswordSubmit(email, resetCode, password)
        user = await Auth.signIn(email, password)
      } else if (authState === AuthStates.NewPassword) {
        user = await Auth.completeNewPassword(cognitoUserState, newPassword)
      } else {
        user = await Auth.signIn(email, password)
      }

      setLoading(false)

      if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setAuthState(AuthStates.NewPassword)
        setCognitoUserState(user)
      } else {
        setUser(user)
      }
    } catch (err: any) {
      setLoading(false)

      if (err.code === 'UserNotConfirmedException') {
        await Auth.resendSignUp(email)
      } else if (err.code === 'NotAuthorizedException') {
        setFormError(err.message as string)
      } else if (err.code === 'UserNotFoundException') {
        setFormError(err.message as string)
      } else if (err.code === 'PasswordResetRequiredException') {
        setAuthState(AuthStates.ResetPassword)
        setFormError(err.message as string)
      } else if (err.code === 'ExpiredCodeException') {
        setAuthState(AuthStates.ExpiredCodeException)
        setFormError(err.message as string)
      } else {
        setFormError(_isString(err) ? err : (err as Error).message)
      }
    }
  }, [email, password, newPassword, resetCode, authState, cognitoUserState])
}
