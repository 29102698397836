import React from 'react'
import { Auth } from 'aws-amplify'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { createBrowserRouter } from 'react-router-dom'

import App from 'components/App/App'
import DB from 'data/db'
import { getLogger } from 'logger'
import { initializeGA } from 'services/ga'
import { initializeAmplify } from 'services/amplify'
import { DB_NAME, DB_VERSION, DB_CONFIG, SENTRY_CONFIG } from 'config'

import AdminPage, { PATH as ADMIN_PAGE_PATH } from 'pages/admin'
import LoginPage, { PATH as LOGIN_PAGE_PATH } from 'pages/login/Login'
import DashboardPage, {
  PATH as DASHBOARD_PAGE_PATH
} from 'pages/dashboard/Dashboard'
import Insights, { InsightsView } from 'pages/insights/Insights'

import 'services/i18n'
import './index.scss'

const { NODE_ENV } = process.env

if (NODE_ENV !== 'test') {
  Sentry.init(SENTRY_CONFIG)
}

const l = getLogger()
initializeGA()
l.success('initialized GA')

initializeAmplify()
l.success('initialized amplify')

const router = createBrowserRouter([
  {
    path: LOGIN_PAGE_PATH,
    element: <LoginPage />
  },
  {
    path: DASHBOARD_PAGE_PATH,
    element: <DashboardPage />
  },
  {
    path: '/analytics',
    element: <Insights view={InsightsView.ANALYTICS} />
  },
  {
    path: '/map',
    element: <Insights view={InsightsView.MAP} />
  },

  {
    path: ADMIN_PAGE_PATH,
    element: <AdminPage />
  }
])

const db = new DB(DB_NAME, DB_VERSION, DB_CONFIG)
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const run = async (): Promise<void> => {
  await db.open()

  const initialUser = await Auth.currentUserInfo()

  l.info('rendering root')

  root.render(
    <React.StrictMode>
      <App db={db} router={router} initialUser={initialUser} />
    </React.StrictMode>
  )
}

// eslint-disable-next-line github/no-then
run().catch((err: Error) => {
  l.error(err)
})
