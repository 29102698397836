import { useEffect, useMemo, useCallback, useState } from 'react'
import cn from 'clsx'
import _min from 'lodash/min'
import _max from 'lodash/max'
import _sum from 'lodash/sum'
import _map from 'lodash/map'
import _keys from 'lodash/keys'
import _mean from 'lodash/mean'
import _isNil from 'lodash/isNil'
import _isNull from 'lodash/isNull'
import _values from 'lodash/values'
import _filter from 'lodash/filter'
import _isUndefined from 'lodash/isUndefined'
import { useTranslation, Trans } from 'react-i18next'

import * as U from 'utils'
import * as H from 'hooks'
import Switch from 'components/Switch/Switch'
import Dropdown from 'components/Dropdown/Dropdown'
import BarChart from 'components/BarChart/BarChart'

import { EmissionsUnit } from 'types/common'
import { type ChartDataPoint } from 'types/ui'
import { type EmissionsData, type Emissions } from 'types/data'
import { type DropdownOption } from 'components/Dropdown/types'

import { CLASS_NAME, DisplayMode, UNIT_OPTIONS } from './const'

import './style.scss'

interface YearDropdownOption {
  label: string
  value: number
}

const convertCO2TonsToUnit = (
  value: number,
  unit: EmissionsUnit,
  area: number
): number => {
  switch (unit) {
    case EmissionsUnit.TonsPerHa:
      return area === 0 ? 0 : value / area
    case EmissionsUnit.KTonsPerHa:
      return (area === 0 ? 0 : value / area) / 1000
    case EmissionsUnit.KTons:
      return value / 1000
    case EmissionsUnit.Tons:
    default:
      return value
  }
}

const convertMonthlyDataToYearly = (data: EmissionsData): EmissionsData => {
  const result: EmissionsData = {}

  _keys(data).forEach((key: string): void => {
    U.setOrAdd(result, key.substring(0, 4), data[key])
  })

  return result
}

const getInterventionStartYearOptions = (
  years: number[],
  endYear: number | null
): YearDropdownOption[] =>
  useMemo((): YearDropdownOption[] => {
    const minYear = _min(years)

    // prettier-ignore
    return _isNil(minYear)
      ? []
      : years.filter(
        (year: number) => year > minYear && (endYear === null || year < endYear)
      ).map((year: number): YearDropdownOption => ({
        label: `${year}`,
        value: year
      }))
  }, [years, endYear])

const getInterventionEndYearOptions = (
  years: number[],
  startYear: number | null
): YearDropdownOption[] =>
  useMemo(
    (): YearDropdownOption[] =>
      years
        .filter((year: number) => startYear === null || year >= startYear)
        .map(
          (year: number): YearDropdownOption => ({
            label: `${year}`,
            value: year
          })
        ),
    [years, startYear]
  )

// prettier-ignore
const getBaselinePeriodOptions = (
  years: number[],
  interventionStartYear: number | null
): number[] =>
  interventionStartYear === null
    ? []
    : _filter(
      _map(years, (year: number): number => interventionStartYear - year),
      (value: number): boolean => value > 0
    )

const convertYearsToDropdownOptions = (
  years: number[],
  labelFormatCb?: (year: number) => string
): YearDropdownOption[] =>
  years.map(
    (year: number): YearDropdownOption => ({
      label: _isUndefined(labelFormatCb) ? `${year}` : labelFormatCb(year),
      value: year
    })
  )

// prettier-ignore
const useOnDropdownChange = <T,>(
  setter: (value: T) => void
): ((item: DropdownOption) => void) =>
    useCallback(
      ({ value }): void => {
        setter(value as T)
      },
      [setter]
    )

const useDefaultNullState = (): [
  number | null,
  (value: number | null) => void
] => useState<number | null>(null)

type Props = {
  className?: string
  emissions: Emissions[]
}

export default function BaselineChart({ emissions, className }: Props) {
  const { t } = useTranslation()
  const totalArea = H.useTotalArea(emissions)
  const years = H.getEmissionsYears(emissions)
  const mergedMonthlyData = H.useFunctionMemo<EmissionsData>(
    U.getEmissionsDataMerged,
    emissions
  )

  const [baselinePeriod, setBaselinePeriod] = useDefaultNullState()
  const [baselineEndYear, setBaselineEndYear] = useDefaultNullState()
  const [baselineStartYear, setBaselineStartYear] = useDefaultNullState()
  const displayModeOptions = useMemo(
    () => [
      {
        label: t('baseline_chart.display_mode_overlay'),
        value: DisplayMode.Overlay
      },
      {
        label: t('baseline_chart.display_mode_excess'),
        value: DisplayMode.Excess
      }
    ],
    [t]
  )

  const [displayMode, setDisplayMode] = useState<DisplayMode>(
    DisplayMode.Overlay
  )

  const yearIsWithinBaseline = useCallback(
    (year: number | string): boolean =>
      baselineStartYear !== null &&
      baselineEndYear !== null &&
      +year >= baselineStartYear &&
      +year <= baselineEndYear,
    [baselineStartYear, baselineEndYear]
  )

  const [interventionEndYear, setInterventionEndYear] = useDefaultNullState()
  const [interventionStartYear, setInterventionStartYear] =
    useDefaultNullState()

  // Forcefully set intervention start & end years if they are no longer valid
  // within the available years array. This can happen when the user updates
  // the start and end year filters externally.
  useEffect(() => {
    if (interventionStartYear === null || interventionEndYear === null) {
      return
    }

    const minYear = _min(years) ?? null
    const maxYear = _max(years) ?? null

    if (minYear !== null && interventionStartYear < minYear + 1) {
      setInterventionStartYear(minYear + 1)
    } else if (maxYear !== null && interventionStartYear > maxYear) {
      setInterventionStartYear(maxYear - 1)
    }

    if (minYear !== null && interventionEndYear < minYear) {
      setInterventionEndYear(minYear)
    } else if (maxYear !== null && interventionEndYear > maxYear) {
      setInterventionEndYear(maxYear)
    }
  }, [years, interventionStartYear, interventionEndYear])

  const yearIsWithinIntervention = useCallback(
    (year: number | string): boolean =>
      interventionStartYear !== null &&
      interventionEndYear !== null &&
      +year >= interventionStartYear &&
      +year <= interventionEndYear,
    [interventionStartYear, interventionEndYear]
  )

  const onSetInterventionStartYear = useOnDropdownChange<number>(
    setInterventionStartYear
  )

  const onSetInterventionEndYear = useOnDropdownChange<number>(
    setInterventionEndYear
  )

  const onSetBaselinePeriod = useOnDropdownChange<number>(setBaselinePeriod)

  const baselinePeriodOptions = useMemo(
    () =>
      convertYearsToDropdownOptions(
        getBaselinePeriodOptions(years, interventionStartYear),
        (year: number): string =>
          `${year}-yr ${t('baseline_chart.baseline_period_average_suffix')}`
      ),
    [years, interventionStartYear]
  )

  useEffect(() => {
    if (baselinePeriodOptions.length > 0 && baselinePeriod === null) {
      setBaselinePeriod(baselinePeriodOptions[0].value)
    }
  }, [baselinePeriodOptions])

  useEffect(() => {
    setBaselineEndYear(
      interventionStartYear === null ? null : interventionStartYear - 1
    )
  }, [interventionStartYear])

  useEffect(() => {
    setBaselineStartYear(
      interventionStartYear === null || baselinePeriod === null
        ? null
        : interventionStartYear - baselinePeriod - 1
    )
  }, [baselinePeriod, interventionStartYear])

  const dataPointCount = _values(mergedMonthlyData).length
  const totalNEE = H.useFunctionMemo<number>(
    U.getEmissionsDataTotal,
    mergedMonthlyData
  )

  const avgNEE = dataPointCount === 0 ? 0 : totalNEE / dataPointCount
  const initialUnit = avgNEE > 1000 ? EmissionsUnit.KTons : EmissionsUnit.Tons
  const [unit, setUnit] = useState<EmissionsUnit>(initialUnit)

  const onSetUnit = useOnDropdownChange<EmissionsUnit>(setUnit)

  const yearlyData = useMemo(
    (): EmissionsData => convertMonthlyDataToYearly(mergedMonthlyData),
    [mergedMonthlyData]
  )

  const avgBaseline = useMemo((): number => {
    if (baselineStartYear === null || baselineEndYear === null) {
      return 0
    }

    const yearlyNEE: EmissionsData = {}

    _keys(yearlyData)
      .filter(yearIsWithinBaseline)
      .forEach((year: string): void => {
        yearlyNEE[year] = yearlyData[year]
      })

    return _mean(_values(yearlyNEE))
  }, [yearlyData, baselineStartYear, baselineEndYear])

  const avgBaselineInUnit = useMemo(
    () => convertCO2TonsToUnit(avgBaseline, unit, totalArea),
    [avgBaseline, unit, totalArea]
  )

  // prettier-ignore
  const chartDataNEETCO2: ChartDataPoint[] = displayMode === DisplayMode.Overlay
    ? _keys(yearlyData)
      .filter(yearIsWithinIntervention)
      .map(
        (year: string): ChartDataPoint => ({
          x: new Date(`${year}`),
          y: yearlyData[year]
        })
      )
    : _keys(yearlyData)
      .filter(yearIsWithinIntervention)
      .map(
        (year: string): ChartDataPoint => ({
          x: new Date(`${year}`),
          y: yearlyData[year] - avgBaseline
        })
      )

  // prettier-ignore
  const additionalFluxInProjectPeriodtCO2 = useMemo(
    (): number =>
      interventionStartYear === null || interventionEndYear === null
        ? 0
        : _sum(
          _keys(yearlyData)
            .filter(yearIsWithinIntervention)
            .map((year: string): number => yearlyData[year] - avgBaseline)
        ),
    [yearlyData, interventionStartYear, interventionEndYear, avgBaseline]
  )

  const additionFluxInLastYeartCO2 = useMemo(() => {
    if (interventionEndYear === null) {
      return 0
    }

    const totalForYear = yearlyData[`${interventionEndYear}`]

    return _isUndefined(totalForYear) ? 0 : totalForYear - avgBaseline
  }, [yearlyData, interventionEndYear, avgBaseline])

  const chartDataInUnit: ChartDataPoint[] = useMemo(
    () =>
      chartDataNEETCO2.map(({ x, y }) => ({
        x,
        y: convertCO2TonsToUnit(y, unit, totalArea)
      })),
    [chartDataNEETCO2, unit, totalArea]
  )

  const additionalFluxInProjectPeriodInUnit = useMemo(
    () =>
      convertCO2TonsToUnit(additionalFluxInProjectPeriodtCO2, unit, totalArea),
    [additionalFluxInProjectPeriodtCO2, unit, totalArea]
  )

  const additionFluxInLastYearInUnit = useMemo(
    () => convertCO2TonsToUnit(additionFluxInLastYeartCO2, unit, totalArea),
    [additionFluxInLastYeartCO2, unit, totalArea]
  )

  const interventionStartYearOptions = getInterventionStartYearOptions(
    years,
    interventionEndYear
  )

  const interventionEndYearOptions = getInterventionEndYearOptions(
    years,
    interventionStartYear
  )

  // prettier-ignore
  const interventionStartYearOption = useMemo(
    (): DropdownOption | null =>
      interventionStartYear === -1
        ? null
        : {
            label: `${interventionStartYear ?? t('baseline_chart.select_start_year')}`,
            value: interventionStartYear
          },
    [interventionStartYear]
  )

  // prettier-ignore
  const interventionEndYearOption = useMemo(
    (): DropdownOption | null =>
      interventionEndYear === -1
        ? null
        : {
            label: `${interventionEndYear ?? t('baseline_chart.select_end_year')}`,
            value: interventionEndYear
          },
    [interventionEndYear]
  )

  const baselinePeriodOption = useMemo(
    (): DropdownOption | null =>
      baselinePeriodOptions.find(({ value }) => value === baselinePeriod) ??
      null,
    [baselinePeriodOptions, baselinePeriod]
  )

  const unitOption = useMemo(
    (): DropdownOption | null =>
      UNIT_OPTIONS.find(({ value }) => value === unit) ?? null,
    [UNIT_OPTIONS, unit]
  )

  const showBaseline =
    displayMode === DisplayMode.Overlay && !_isNull(baselinePeriod)
  const baselineValue = convertCO2TonsToUnit(avgBaseline, unit, totalArea)

  // prettier-ignore
  return (
    <div className={cn(CLASS_NAME, className)}>
      <div className={`${CLASS_NAME}-right`}>
        <div className={`${CLASS_NAME}-title`}>
          <Trans i18nKey="baseline_chart.title" />
        </div>
        <div className={`${CLASS_NAME}-controls`}>
          <div className={`${CLASS_NAME}-controls-left`}>
            <Dropdown
              value={interventionStartYearOption}
              onChange={onSetInterventionStartYear}
              options={interventionStartYearOptions}
              label={t('baseline_chart.intervention_start')}
              helpText={t('baseline_chart.intervention_start_help')}
            />
            <Dropdown
              value={interventionEndYearOption}
              onChange={onSetInterventionEndYear}
              options={interventionEndYearOptions}
              label={t('baseline_chart.intervention_end')}
              helpText={t('baseline_chart.intervention_end_help')}
            />
            <Dropdown
              value={baselinePeriodOption}
              onChange={onSetBaselinePeriod}
              options={baselinePeriodOptions}
              placeholder={t('dropdown_placeholder')}
              label={t('baseline_chart.baseline_period')}
              disabled={baselinePeriodOptions.length === 0}
              helpText={t('baseline_chart.baseline_period_help')}
            />
            <div className={`${CLASS_NAME}-controls-left-vertical-container`}>
              <Dropdown
                value={unitOption}
                onChange={onSetUnit}
                options={UNIT_OPTIONS}
                label={t('unit_dropdown.label')}
                placeholder={t('unit_dropdown.placeholder')}
              />
              <p>
                <Trans i18nKey="baseline_chart.display_mode_switch_label" />
              </p>
              <Switch
                value={displayMode}
                onChange={setDisplayMode}
                optionA={displayModeOptions[0]}
                optionB={displayModeOptions[1]}
              />
            </div>
          </div>
        </div>
        <div className={`${CLASS_NAME}-chart-wrapper`}>
          {(_isNull(interventionStartYear) || _isNull(interventionEndYear)) && (
            <div className={`${CLASS_NAME}-placeholder`}>
              <Trans i18nKey="baseline_chart.instructions" />
            </div>
          )}
          {!_isNull(interventionStartYear) && !_isNull(interventionEndYear) && (
            <BarChart
              hideMonth
              height={300}
              barPadding={0.4}
              data={chartDataInUnit}
              title={t('baseline_chart.chart_title')}
              labelX={t('baseline_chart.chart_label_x')}
              labelY={t('baseline_chart.chart_label_y', { unit })}
              {...(showBaseline ? { baselineValue } : {})}
            />
          )}
        </div>
        {!_isNull(interventionStartYear) && !_isNull(interventionEndYear) && (
          <div className={`${CLASS_NAME}-stats`}>
            <div className={`${CLASS_NAME}-stats-item`}>
              <div className={`${CLASS_NAME}-stats-item-labels`}>
                <p>
                  <Trans i18nKey="baseline_chart.stat_average_baseline_title" />
                </p>
                <p>
                  <Trans i18nKey="baseline_chart.stat_average_baseline_description" />
                </p>
              </div>
              <div
                className={cn(`${CLASS_NAME}-stats-item-value`, {
                  red: avgBaselineInUnit > 0
                })}
              >
                <p>
                  {U.formatUINumber(avgBaselineInUnit)} {unit}
                </p>
              </div>
            </div>
            <div className={`${CLASS_NAME}-stats-item`}>
              <div className={`${CLASS_NAME}-stats-item-labels`}>
                <p>
                  <Trans i18nKey="baseline_chart.stat_additional_flux_over_project_period_title" />
                </p>
                <p>
                  <Trans i18nKey="baseline_chart.stat_additional_flux_over_project_period_description" />
                </p>
              </div>
              <div
                className={cn(`${CLASS_NAME}-stats-item-value`, {
                  red: additionalFluxInProjectPeriodInUnit > 0
                })}
              >
                <p>
                  {U.formatUINumber(additionalFluxInProjectPeriodInUnit)} {unit}
                </p>
              </div>
            </div>
            <div className={`${CLASS_NAME}-stats-item`}>
              <div className={`${CLASS_NAME}-stats-item-labels`}>
                <p>
                  <Trans i18nKey="baseline_chart.stat_additional_flux_in_last_year_title" />
                </p>
                <p>
                  <Trans i18nKey="baseline_chart.stat_additional_flux_in_last_year_description" />
                </p>
              </div>
              <div
                className={cn(`${CLASS_NAME}-stats-item-value`, {
                  red: additionFluxInLastYearInUnit > 0
                })}
              >
                <p>
                  {U.formatUINumber(additionFluxInLastYearInUnit)} {unit}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export { CLASS_NAME }
