import { useCallback } from 'react'
import _noop from 'lodash/noop'
import _isUndefined from 'lodash/isUndefined'

import * as H from '../../hooks'

import { CLASS_NAME } from './const'

import './style.scss'

const DEFAULT_TYPE = 'text'

type Props = {
  className?: string
  type?: string
  onChange?: (value: string) => void
  placeholder?: string
  label?: string
  value?: string
  id: string
  disabled?: boolean
}

export default function Input({
  type = DEFAULT_TYPE,
  placeholder,
  label,
  className,
  onChange,
  disabled,
  value,
  id
}: Props) {
  const finalClassName = H.useClassName(CLASS_NAME, className, {
    disabled: disabled === true
  })

  const onInputChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (disabled === true || _isUndefined(onChange)) {
        return
      }

      const { currentTarget } = e
      const { value } = currentTarget

      onChange(value.trim())
    },
    [onChange, disabled]
  )

  return (
    <>
      {label != null && <label htmlFor={id}>{label}</label>}
      <input
        id={id}
        type={type}
        value={value}
        disabled={disabled}
        onChange={disabled === true ? _noop : onInputChange}
        placeholder={placeholder}
        className={finalClassName}
        name={id}
      />
    </>
  )
}

export { CLASS_NAME }
