import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import * as U from 'utils'
import { getLogger } from 'logger'
import { API, type DB } from 'data'
import { type Feature } from 'types'
import { DB_FEATURES_STORE_NAME } from 'config'

interface Props {
  db: DB
  sub: string
  currentSub: string | null
  setEmissions: (emissions: any) => void
  setFeatures: (features: any) => void
  setIsLoading: (isLoading: boolean) => void
  setCurrentSub: (currentSub: string) => void
  setLoadingMessage: (loadingMessage: string) => void
  setLoadingProgress: (progress: number) => void
}

const api = new API()
const l = getLogger('ui:hooks:use_data')

const useData = ({
  db,
  sub,
  currentSub,
  setEmissions,
  setFeatures,
  setIsLoading,
  setCurrentSub,
  setLoadingMessage,
  setLoadingProgress
}: Props): void => {
  const { t } = useTranslation()

  useEffect(() => {
    const run = async (): Promise<void> => {
      if (sub === currentSub) {
        return
      }

      setIsLoading(true)
      setLoadingProgress(25)
      setLoadingMessage(t('data_loading.assets'))

      const allCachedFeatures = await db.getAllItems(DB_FEATURES_STORE_NAME)
      const allCachedFeaturesForSub = allCachedFeatures.filter(
        (feature: any) => {
          const { key } = feature
          const featureSub = key.split('|')[0]

          return featureSub === sub || featureSub.length === 0
        }
      )

      let allFeatures: Feature[] =
        allCachedFeaturesForSub.length === 0 ? [] : allCachedFeaturesForSub
      let allFeaturesWithTags: any = []

      if (allFeatures.length === 0) {
        try {
          allFeatures = await api.getFeatures(sub)
        } catch (err: any) {
          setEmissions(null)
          setFeatures(null)
          setIsLoading(false)
          setCurrentSub(sub)
          setLoadingMessage('')
          setLoadingProgress(0)

          // eslint-disable-next-line i18n-text/no-en
          l.error(`Error fetching GeoJSON: ${err.message}`)

          return
        }
      }

      if (allFeaturesWithTags.length === 0) {
        try {
          allFeaturesWithTags = await api.getFeaturesWithTags(sub)
        } catch (err) {
          console.error('Error fetching features with tags:', err)
        }
      }

      const getTagsForPolygon = (featureUuid: string): string[] =>
        allFeaturesWithTags?.[featureUuid] ?? []

      const allPolygonsWithTags = allFeatures.map((feature: Feature) => ({
        ...feature,
        properties: {
          ...feature.properties,
          tags: getTagsForPolygon(feature.properties.feature_uuid)
        }
      }))

      const srcFeatures = U.flattenFeaturesByLCC(allPolygonsWithTags)
      let loadedFeatureCount = 0

      await Promise.all(
        srcFeatures.map(async (feature: Feature) => {
          const { properties } = feature
          const { feature_uuid: featureUUID, land_cover: featureLCC } =
            properties

          await U.ensureFeatureIsCached(
            db,
            feature,
            sub,
            featureUUID,
            featureLCC
          )

          setLoadingProgress(
            25 +
              Math.floor(((loadedFeatureCount + 1) / srcFeatures.length) * 25)
          )

          loadedFeatureCount += 1
        })
      )

      setLoadingMessage(t('data_loading.emissions'))

      let loadedEmissionsCount = 0

      await Promise.all(
        srcFeatures.map(async (feature: Feature) => {
          const { properties } = feature
          const { feature_uuid: featureUUID, land_cover: featureLCC } =
            properties

          await U.ensureEmissionsIsCached(db, api, sub, featureUUID, featureLCC)

          setLoadingProgress(
            50 +
              Math.floor(((loadedEmissionsCount + 1) / srcFeatures.length) * 25)
          )

          loadedEmissionsCount += 1
        })
      )

      setLoadingMessage(t('data_loading.geometry'))

      let loadedGeometryCount = 0

      await Promise.all(
        srcFeatures.map(async (feature: Feature) => {
          const { properties } = feature
          const { feature_uuid: featureUUID, land_cover: featureLCC } =
            properties

          await U.ensureGeometryIsCached(db, api, sub, featureUUID, featureLCC)

          setLoadingProgress(
            75 +
              Math.floor(((loadedGeometryCount + 1) / srcFeatures.length) * 25)
          )

          loadedGeometryCount += 1
        })
      )

      setLoadingProgress(100)
      setLoadingMessage('')
      setIsLoading(false)
      setCurrentSub(sub)

      const { features, emissions } = await U.getEmissionsData(sub, db)

      setEmissions(emissions)
      setFeatures(features)
    }

    // eslint-disable-next-line github/no-then
    run().catch((err: any) => {
      setIsLoading(false)
      setLoadingMessage('')
      setLoadingProgress(0)

      l.error('error loading data:')
      l.error(err)
    })
  }, [t, db, sub, currentSub])
}

export default useData
