export const CLASS_NAME = 'cst-page-login'
export const DOCUMENT_TITLE = 'Login'
export const PATH = '/login'

export enum AuthStates {
  SignIn = 'SignIn',
  LoggedIn = 'LoggedIn',
  SignedOut = 'SignedOut',
  NewPassword = 'NewPassword',
  ResetPassword = 'ResetPassword',
  ResetPasswordStep2 = 'ResetPasswordStep2',
  ExpiredCodeException = 'ExpiredCodeException'
}
