import * as d3 from 'd3'
import { useMemo } from 'react'

export default function useScaleY(
  min: number,
  max: number,
  height: number
): any {
  return useMemo(
    (): any =>
      d3
        .scaleLinear()
        .domain([min > 0 ? 0 : min * 1.1, max < 0 ? 0 : max * 1.1])
        .range([height, 0]),
    [min, max, height]
  )
}
