import _sum from 'lodash/sum'
import _uniq from 'lodash/uniq'
import _compact from 'lodash/compact'
import _isArray from 'lodash/isArray'

import getEmissionsDataTotal from './get_emissions_data_total'
import getEmissionsDataMerged from './get_emissions_data_merged'

import { CountryCode } from 'const/ui'
import { IGBPLandCoverClassNames } from 'types/common'
import { type Feature, type Emissions, type EmissionsGroup } from 'types/data'

/**
 * Returns a single Emissions object with the internal feature data having all
 * properties from the features in the group. (i.e. name, site name, etc)
 */
const getMergedEmissionsForGroup = (group: EmissionsGroup): Emissions => {
  const { key, polygons } = group
  const features: Feature[] =
    polygons?.map(({ feature }: { feature: any }) => feature) ?? []
  const data = getEmissionsDataMerged(polygons)
  const total = getEmissionsDataTotal(data)
  const totalArea = _sum(
    polygons.map(({ feature }) => feature?.properties?.area ?? 0)
  )
  const name = _compact(
    _uniq(features.map(({ properties }) => properties.name ?? ''))
  )
    .join(', ')
    .trim()
  const siteName = _compact(
    _uniq(features.map(({ properties }) => properties.site_name))
  )
    .join(', ')
    .trim()

  // Handle single or multiple LCC types
  // prettier-ignore
  const landCover = _compact(
    _uniq(
      features.map(({ properties }) =>
        _isArray(properties.land_cover)
          ? properties.land_cover
            .map(
              (lcc: string) =>
                IGBPLandCoverClassNames[
                  lcc as keyof typeof IGBPLandCoverClassNames
                ]
            )
            .join(', ')
          : IGBPLandCoverClassNames[
            properties.land_cover as keyof typeof IGBPLandCoverClassNames
          ]
      )
    )
  )

  const countryCode = _compact(
    _uniq(
      features.map(
        ({ properties }) =>
          CountryCode[properties.country_code as keyof typeof CountryCode] ??
          'Unknown'
      )
    )
  )
    .join(', ')
    .trim()

  const feature = {
    properties: {
      // eslint-disable-next-line camelcase
      site_name: siteName.length === 0 ? 'None' : siteName,

      // eslint-disable-next-line camelcase
      land_cover: landCover.length === 0 ? 'None' : landCover,

      // eslint-disable-next-line camelcase
      country_code: countryCode.length === 0 ? 'None' : countryCode,
      name: name.length === 0 || name.includes(',') ? `Group ${key}` : name,
      area: totalArea
    }
  }

  return {
    data,
    total,
    area: totalArea,
    id: polygons.map(({ id }) => id).join('-'),
    feature: feature as Feature,
    featureWithGeometry: feature as Feature,
    totalIntensity: totalArea === 0 ? 0 : total / totalArea
  }
}

export default getMergedEmissionsForGroup
