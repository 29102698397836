import cn from 'clsx'
import * as d3 from 'd3'
import * as d3fc from 'd3fc'
import React, { useMemo, useRef, useEffect } from 'react'

import { CLASS_NAME } from './const'

import './style.scss'

type Props = {
  scaleX: any
  scaleY: any
  temporal?: boolean
  className?: string
}

export default function ChartAxisGridLines({
  className,
  scaleX,
  scaleY,
  temporal = false
}: Props) {
  const ref = useRef<any>(null)

  const gridline = useMemo(
    () => d3fc.annotationSvgGridline().xScale(scaleX).yScale(scaleY),
    [scaleX, scaleY]
  )

  useEffect((): void => {
    const { current } = ref

    if (current === null) return

    d3.select(current).call(gridline)

    if (!temporal) {
      d3.select(current).selectAll('.gridline-y').remove()
    }
  }, [ref.current, gridline])

  return <g className={cn(CLASS_NAME, className)} ref={ref} />
}
